import React, {useState} from 'react';
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';

import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Heading from '../../../common/components/Heading';
import Button from '../../../common/components/Button';
import Input from '../../../common/components/Input';
import Container from '../../../common/components/UI/Container';

import NewsletterWrapper, {ContactFormWrapper} from './newsletter.style';
import useEnvContext from '../../../hooks/useEnvContext';

/**
 * @link https://thetrevorharmon.com/blog/email-signup-forms-with-gatsby-and-mailchimp
 */

const Newsletter = ({sectionWrapper, textArea, buttonArea, buttonStyle, title, description}) => {
	const [email, setEmail] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();

		addToMailchimp(email)
			.then((data) => {
				if (data.result === 'error') {
					if (data.msg === 'captcha') {
						document.getElementById('frmMessage').innerHTML = 'There has been an error. Please try again later!';
					} else {
						document.getElementById('frmMessage').innerHTML = data.msg || "There's been an error. Please try again!";
					}

					console.log(data);
				} else {
					document.getElementById('frmMessage').innerHTML = 'Thank you!';
				}
			})
			.catch((error) => {
				// Errors in here are client side
				// Mailchimp always returns a 200
				console.error(error);
				document.getElementById('frmMessage').innerHTML = "There's been an error. Please try again!";
			});
	};

	const handleEmailChange = (eml) => {
		setEmail(eml);
	};

	const {domain} = useEnvContext();

	return (
		<Box {...sectionWrapper} as="section">
			<NewsletterWrapper>
				<Container>
					<Box {...textArea}>
						<Heading content={domain === 'jump.me' ? 'Be first to Jump' : 'Be first to Jump in'} {...title} />
						<Text
							content={domain === 'jump.me' ? 'The app is available now on iOS in selected countries and cities (Android version coming soon!)' : 'Jump in is available now on iOS in selected countries and cities (Android version coming soon!)'}
							{...description}
						/>
					</Box>
					<Box {...buttonArea}>
						<form onSubmit={handleSubmit}>
							<ContactFormWrapper>
								<Input
									inputType="email"
									label="Email address"
									iconPosition="right"
									isMaterial={true}
									className="email_input"
									onChange={handleEmailChange}
								/>
								<Button {...buttonStyle} title="Notify me" type="submit" />
							</ContactFormWrapper>
							<StyledMessage id="frmMessage" />
						</form>
					</Box>
				</Container>
			</NewsletterWrapper>
		</Box>
	);
};

Newsletter.propTypes = {
	sectionWrapper: PropTypes.object,
	textArea: PropTypes.object,
	buttonArea: PropTypes.object,
	buttonStyle: PropTypes.object,
	title: PropTypes.object,
	description: PropTypes.object
};

Newsletter.defaultProps = {
	sectionWrapper: {},
	textArea: {
		mb: ['40px', '40px', '40px', '0', '0'],
		pr: ['0', '0', '0', '80px', '100px']
	},
	title: {
		fontSize: ['18px', '20px', '22px', '24px', '26px'],
		fontWeight: '500',
		color: '#fff',
		lineHeight: '1.34',
		mb: ['14px', '14px', '14px', '14px', '13px'],
		textAlign: ['center', 'center', 'center', 'left', 'left'],
		letterSpacing: '-0.025em'
	},
	description: {
		fontSize: ['14px', '14px'],
		maxWidth: ['100%', '400px'],
		fontWeight: '400',
		color: '#fefefe',
		lineHeight: '1.7',
		mb: 0,
		textAlign: ['center', 'center', 'center', 'left', 'left']
	},
	buttonArea: {
		zIndex: 1
	},
	buttonStyle: {
		type: 'button',
		fontSize: '14px',
		fontWeight: '700',
		pl: '30px',
		pr: '30px'
	}
};

const StyledMessage = styled.div`
	display: block;
	font-weight: bold;
	font-size: 1rem;
	margin-top: 0.6rem;
	color: white;
`;

export default Newsletter;
